import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PersonalDetailsFormValues } from "./personal-details";

type Props = {
  showUnderageTerms?: boolean;
};

export const TermsForm = ({ showUnderageTerms = false }: Props) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<PersonalDetailsFormValues>();

  return (
    <VStack spacing={4} w="full">
      {/* <FormControl isInvalid={!!errors.acceptMarketing}>
        <Checkbox alignItems="start" {...register("acceptMarketing")}>
          <Text textAlign="start" mt={-1}>
            Me gustaría recibir información sobre promociones especiales,
            novedades y eventos que se realicen en el club.
          </Text>
        </Checkbox>
        {!!errors.acceptMarketing && errors.acceptMarketing?.message ? (
          <FormErrorMessage>{errors.acceptMarketing?.message}</FormErrorMessage>
        ) : null}
      </FormControl> */}
      <FormControl isInvalid={!!errors.healthTerms}>
        <Checkbox alignItems="start" {...register("healthTerms")}>
          <Text textAlign="start" mt={-1}>
            {showUnderageTerms
              ? t("personalDetails.terms.underageHealthTerms")
              : t("personalDetails.terms.healthTerms")}
          </Text>
        </Checkbox>
        {!!errors.healthTerms && errors.healthTerms?.message ? (
          <FormErrorMessage>{errors.healthTerms?.message}</FormErrorMessage>
        ) : null}
      </FormControl>
      {showUnderageTerms && (
        <FormControl isInvalid={!!errors.underageTerms}>
          <Checkbox alignItems="start" {...register("underageTerms")}>
            <Text textAlign="start" mt={-1}>
              {t("personalDetails.terms.underageTerms")}
            </Text>
          </Checkbox>
          {!!errors.underageTerms && errors.underageTerms?.message ? (
            <FormErrorMessage>{errors.underageTerms?.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      )}
    </VStack>
  );
};
