import { GridItem, Select, SimpleGrid } from "@chakra-ui/react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

dayjs.extend(customParseFormat);

const months = [
  { name_es: "Enero", value: "01", name_en: "January" },
  { name_es: "Febrero", value: "02", name_en: "February" },
  { name_es: "Marzo", value: "03", name_en: "March" },
  { name_es: "Abril", value: "04", name_en: "April" },
  { name_es: "Mayo", value: "05", name_en: "May" },
  { name_es: "Junio", value: "06", name_en: "June" },
  { name_es: "Julio", value: "07", name_en: "July" },
  { name_es: "Agosto", value: "08", name_en: "August" },
  { name_es: "Septiembre", value: "09", name_en: "September" },
  { name_es: "Octubre", value: "10", name_en: "October" },
  { name_es: "Noviembre", value: "11", name_en: "November" },
  { name_es: "Diciembre", value: "12", name_en: "December" },
];

function getDays() {
  return Array.from({ length: 31 }, (_, i) => i + 1);
}

function getYears() {
  return Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
}

type Props = {
  id: string;
  onChange: (value: string) => void;
  value: string;
};

export function DateSelect({ id, value = "---", onChange }: Props) {
  const { t, i18n } = useTranslation();

  const [year, month, day] = value.split("-");

  const handleDayChange = (value: string) => {
    onChange(`${year}-${month}-${value}`);
  };

  const handleMonthChange = (value: string) => {
    onChange(`${year}-${value}-${day}`);
  };

  const handleYearChange = (value: string) => {
    onChange(`${value}-${month}-${day}`);
  };

  return (
    <SimpleGrid columns={12} spacingX={2}>
      <GridItem colSpan={3}>
        <Select
          id={`${id}_day`}
          onChange={(e) => handleDayChange(e.target.value)}
          value={day}
          size="lg"
          border="1px"
          borderColor="gray.300"
        >
          <option value="" disabled>
            {t("dateSelector.day")}
          </option>
          {getDays().map((day) => (
            <option key={day} value={`0${day}`.slice(-2)}>
              {day}
            </option>
          ))}
        </Select>
      </GridItem>
      <GridItem colSpan={5}>
        <Select
          id={`${id}_month`}
          onChange={(e) => handleMonthChange(e.target.value)}
          value={month}
          size="lg"
          border="1px"
          borderColor="gray.300"
        >
          <option value="" disabled>
            {t("dateSelector.month")}
          </option>
          {months.map((month) => (
            <option key={month.value} value={month.value}>
              {i18n.language.startsWith("en") ? month.name_en : month.name_es}
            </option>
          ))}
        </Select>
      </GridItem>
      <GridItem colSpan={4}>
        <Select
          id={`${id}_year`}
          onChange={(e) => handleYearChange(e.target.value)}
          value={year}
          size="lg"
          border="1px"
          borderColor="gray.300"
        >
          <option value="" disabled>
            {t("dateSelector.year")}
          </option>
          {getYears().map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </GridItem>
    </SimpleGrid>
  );
}
