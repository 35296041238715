import {
  Avatar,
  Box,
  Container,
  HStack,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon, LanguageIcon } from "@heroicons/react/24/outline";
import { signOut } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../auth/auth-context/auth-context";
import { auth } from "../auth/config";
import { Routes } from "../helpers/router";
import { useSource } from "../helpers/use-source";
import logoImg from "../logo.svg";

import { Button } from "../components/chackra-ui-button";

type Props = {
  children: React.ReactNode | React.ReactNode[];
  profile?: {
    name: string;
    avatarUrl?: string | null;
  };
  maxWidth?: "container.sm" | "container.md" | "container.lg";
};

export const AppContainer = ({
  children,
  profile,
  maxWidth = "container.sm",
}: Props) => {
  const { user } = useAuth();
  const { i18n, t } = useTranslation();
  const source = useSource();

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
    document.documentElement.lang = newLanguage;
  };

  return (
    <Box minH="100vh" display="flex" flexDirection="column">
      {source !== "app" && (
        <Box
          as="nav"
          position="sticky"
          top={0}
          backgroundColor="white"
          zIndex={10}
          borderBottomWidth={1}
          height={{ base: 16, md: 20 }}
        >
          <HStack
            alignItems="center"
            justify="space-between"
            px={{ base: 4, md: 12 }}
            py={{ base: 4, md: 6 }}
            h="100%"
          >
            <a href={"/?nocache=" + new Date().getTime()}>
              <Image h={{ base: 4, md: 6 }} src={logoImg} />
            </a>
            <HStack spacing={{ base: 4, md: 6 }}>
              {source === "kiosk" ? <span>{APP_VERSION}</span> : null}
              <Box display="inline-flex" position="relative" borderWidth={1}>
                <Icon
                  pointerEvents="none"
                  position="absolute"
                  top="50%"
                  transform="translateY(-50%)"
                  left={2}
                  right="auto"
                  zIndex={1}
                  boxSize={{ base: 4, md: 5 }}
                >
                  <LanguageIcon />
                </Icon>
                <Select
                  variant="ghost"
                  size={{ base: "sm", md: "md" }}
                  style={{ paddingLeft: 32 }}
                  value={i18n.language}
                  onChange={handleLanguageChange}
                >
                  <option value="es">Español</option>
                  <option value="en">English</option>
                </Select>
              </Box>
              {user ? (
                profile ? (
                  <Menu>
                    <MenuButton>
                      <HStack>
                        <Avatar
                          size={{ base: "sm", md: "md" }}
                          name={profile.name}
                          src={profile.avatarUrl ?? undefined}
                        />
                        <Icon>
                          <ChevronDownIcon />
                        </Icon>
                      </HStack>
                    </MenuButton>
                    <MenuList>
                      {/* <MenuItem
                        to={Routes.Profiles()}
                        as={RouterLink}
                        icon={
                          <Icon boxSize={6}>
                            <ArrowsRightLeftIcon />
                          </Icon>
                        }
                      >
                        {t("appContainer.changeProfile")}
                      </MenuItem>
                      <MenuDivider /> */}
                      <MenuItem onClick={() => signOut(auth)}>
                        {t("appContainer.signOut")}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                ) : (
                  <Button
                    variant="link"
                    color="black"
                    onClick={() =>
                      signOut(auth).then(() => {
                        if (source === "kiosk") {
                          i18n.changeLanguage("es");
                          document.documentElement.lang = "es";
                        }
                      })
                    }
                  >
                    <Text fontSize={{ base: "sm", md: "md" }}>
                      {t("appContainer.signOut")}
                    </Text>
                  </Button>
                )
              ) : (
                <Button
                  variant="link"
                  color="black"
                  as={RouterLink}
                  to={Routes.Login()}
                >
                  <Text fontSize={{ base: "sm", md: "md" }}>
                    {t("appContainer.signIn")}
                  </Text>
                </Button>
              )}
            </HStack>
          </HStack>
        </Box>
      )}

      <Container
        maxW={maxWidth}
        minH={
          source === "app"
            ? "100vh"
            : {
                base: "calc(100vh - var(--chakra-sizes-16))",
                md: "calc(100vh - var(--chakra-sizes-20))",
              }
        }
        display="flex"
        flexDirection="column"
        position="relative"
        py={{ base: 6, md: 24 }}
      >
        {children}
      </Container>
    </Box>
  );
};
