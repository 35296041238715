import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import es from "./locales/es.json";
import en from "./locales/en.json";

export const resources = {
  es: {
    translation: es,
  },
  en: {
    translation: en,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "es",
    resources: {
      es: {
        translation: es,
      },
      en: {
        translation: en,
      },
    },
    detection: {
      order: ["querystring", "localStorage", "navigator"],
    },
    cache: ["localStorage"],
    react: {
      useSuspense: false,
    },
  });
