import firebase from "firebase/auth";

export enum AuthActionKind {
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",
}

type AuthAction =
  | {
      type: AuthActionKind.LOGGED_IN;
      user: firebase.User;
    }
  | {
      type: AuthActionKind.LOGGED_OUT;
    };

export type AuthState = {
  loading: boolean;
  user: firebase.User | null;
};

export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case AuthActionKind.LOGGED_IN:
      return {
        ...state,
        loading: false,
        user: action.user,
      };
    case AuthActionKind.LOGGED_OUT:
      return {
        ...state,
        loading: false,
        user: null,
      };
    default:
      return state;
  }
};
