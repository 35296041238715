import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { useAuth } from "../../auth/auth-context/auth-context";
import { Routes } from "../../helpers/router";
import { LoadingScreen } from "../loading-screen";

export const PrivateRoutes = () => {
  const [searchParams] = useSearchParams();
  const { user, loading } = useAuth();
  const token = searchParams.get("token");

  if (loading) {
    return <LoadingScreen />;
  }

  if (!user && !token) {
    return <Navigate to={Routes.Login()} />;
  }

  return <Outlet />;
};
