import { Container } from "@chakra-ui/react";
import { Navigate, useSearchParams } from "react-router-dom";
import { AppContainer } from "../../components/app-container";
import { VerifyResetPasswordForm } from "./verify-reset-password-form";
import { Routes } from "../../helpers/router";
export const UserManagement = () => {
  const [params] = useSearchParams();
  const mode = params.get("mode");
  const oobCode = params.get("oobCode");

  if (!mode || !oobCode) {
    return <Navigate to={Routes.Login()} replace />;
  }

  return (
    <Container
      maxW="md"
      display="flex"
      flexDirection="column"
      flex={1}
      justifyContent="center"
      alignItems="stretch"
    >
      {mode === "resetPassword" ? (
        <VerifyResetPasswordForm oobCode={oobCode} />
      ) : null}
    </Container>
  );

  //   return (
  //     <React.Fragment>
  //       <Head>
  //         <title>{pageTitleMap()}</title>
  //         <meta
  //           name="description"
  //           content="Gimnasio Torre del Mar Sensei, fitness, deporte y salud. Conoce nuestro gimnasio, actividades dirigidas, entrenamiento personal y mucho más."
  //           key="desc"
  //         />
  //       </Head>
  //       <main className="flex flex-col md:justify-center expanded">
  //         {mode === "resetPassword" ? (
  //           <UserManagementResetPasswordForm oobCode={oobCode} />
  //         ) : null}
  //       </main>
  //       <Footer />
  //     </React.Fragment>
  //   );
};

// export const getServerSideProps: GetServerSideProps = async (context) => {
//   const { query } = context;

//   const mode = query["mode"];
//   const oobCode = query["oobCode"];

//   if (!mode || !oobCode) {
//     return { props: {}, redirect: { destination: "/404" } };
//   }

//   return {
//     props: {
//       mode,
//       oobCode,
//     },
//   };
// };
