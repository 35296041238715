import { GridItem, Input, Select, SimpleGrid } from "@chakra-ui/react";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { countries as countriesData } from "../helpers/countries";

type Props = {
  id: string;
  onChange: (value: string) => void;
};

export function PhoneInput({ id, onChange }: Props) {
  const [country, setCountry] = useState<string>("ES");
  const [number, setNumber] = useState<string>("");
  // Needs to clone it to avoid mutating the original array
  const countries = [...countriesData];

  useEffect(() => {
    try {
      const countryCode = country as CountryCode;
      const phoneNumber = parsePhoneNumber(number, countryCode).number;
      if (phoneNumber) {
        onChange(phoneNumber);
      }
    } catch (error) {
      onChange(number);
    }
  }, [country, number]);

  return (
    <SimpleGrid columns={12} spacingX={2}>
      <GridItem colSpan={4} maxWidth={96}>
        <Select
          id={`${id}_country`}
          onChange={(e) => setCountry(e.target.value)}
          value={country}
          size="lg"
          border="1px"
          borderColor="gray.300"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {countries
            .sort((a, b) => (a.code > b.code ? 1 : -1))
            .map((country) => (
              <option key={country.code} value={country.code}>
                {country.code} {country.dial_code}
              </option>
            ))}
        </Select>
      </GridItem>
      <GridItem colSpan={8}>
        <Input
          id={`${id}_number`}
          onChange={(e) => setNumber(e.target.value)}
          value={number}
          size="lg"
          border="1px"
          borderColor="gray.300"
        />
      </GridItem>
    </SimpleGrid>
  );
}
