import {
  Box,
  ChakraProvider,
  extendTheme,
  Heading,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import ReactDOM from "react-dom/client";
import App from "./app";

import "@fontsource/inter/latin-400.css";
import "@fontsource/inter/latin-500.css";
import "@fontsource/inter/latin-600.css";
import "@fontsource/inter/latin-700.css";
import "@fontsource/inter/latin-900.css";
import "./instrument";
import "./localization/i18n";

import { AuthProvider } from "./auth/auth-context/auth-context";
import { ApolloProvider } from "@apollo/client";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { IdleTimerProvider } from "react-idle-timer";
import { BrowserRouter } from "react-router-dom";
import { apolloClient } from "./apollo";
import { Button } from "./components/chackra-ui-button";

const colors = {
  blue: {
    "50": "#B8D9FF",
    "100": "#A3CEFF",
    "200": "#7AB9FF",
    "300": "#52A3FF",
    "400": "#298EFF",
    "500": "#0078FF",
    "600": "#005EC7",
    "700": "#00438F",
    "800": "#002957",
    "900": "#000E1F",
  },
};

const fonts = {
  heading: `'Inter', sans-serif`,
  body: `'Inter', sans-serif`,
};

const theme = extendTheme({
  colors,
  fonts,
  components: {
    Button: { baseStyle: { borderRadius: 0 } },
    Input: {
      sizes: {
        xs: {
          field: {
            borderRadius: 0,
          },
        },
        sm: {
          field: {
            borderRadius: 0,
          },
        },
        md: {
          field: {
            borderRadius: 0,
          },
        },
        lg: {
          field: {
            borderRadius: 0,
          },
        },
      },
    },
    Select: {
      sizes: {
        xs: {
          field: {
            borderRadius: 0,
          },
        },
        sm: {
          field: {
            borderRadius: 0,
          },
        },
        md: {
          field: {
            borderRadius: 0,
          },
        },
        lg: {
          field: {
            borderRadius: 0,
          },
        },
      },
    },
  },
});

const FallbackComponent = () => {
  const { t } = useTranslation();
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.50"
    >
      <VStack spacing={4} textAlign="center" maxWidth="640px" p={4}>
        <Icon as={ExclamationCircleIcon} w={12} h={12} color="red.500" />
        <Heading size="lg">{t("errorBoundary.title")}</Heading>
        <Text>{t("errorBoundary.message")}</Text>
        <Button
          colorScheme="blue"
          onClick={() => window.location.reload()}
          borderRadius={4}
        >
          {t("errorBoundary.refreshButton")}
        </Button>
      </VStack>
    </Box>
  );
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <SentryErrorBoundary fallback={FallbackComponent}>
        <AuthProvider>
          <ApolloProvider client={apolloClient}>
            <BrowserRouter>
              <IdleTimerProvider>
                <App />
              </IdleTimerProvider>
            </BrowserRouter>
          </ApolloProvider>
        </AuthProvider>
      </SentryErrorBoundary>
    </ChakraProvider>
  </React.StrictMode>
);
