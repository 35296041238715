import { useToast, VStack } from "@chakra-ui/react";
import QRCode from "react-qr-code";
import { ContainerLoading } from "../../components/container-loading";
import { useAccessCredentialQuery } from "../../graphql/generated/user-hooks";

export const Credential = () => {
  const toast = useToast();
  const { loading, data, error } = useAccessCredentialQuery({
    fetchPolicy: "network-only",
    context: { endpoint: "fitnext" },
    onError: (error) => {
      toast({
        title: "Error al obtener la credencial de acceso",
        status: "error",
        duration: 5000,
      });
    },
  });

  if (loading || error || !data?.accessCredential) {
    return <ContainerLoading />;
  }

  return (
    <VStack w="full">
      <QRCode value={data.accessCredential} />
    </VStack>
  );
};
