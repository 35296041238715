import { VStack } from "@chakra-ui/react";
import { useReducer } from "react";

import "dayjs/locale/es";
import {
  DashboardActionKind,
  DashboardModal,
  dashboardReducer,
  initialDashboardState,
} from "./dashboard.reducer";
import { InvoiceSection } from "./invoice-section";
import { SubscriptionCancelModal } from "./subscription-cancel-modal";
import { SubscriptionReactivateModal } from "./subscription-reactivate-modal";
import { SubscriptionSection } from "./subscription-section";

export const Dashboard = () => {
  const [state, dispatch] = useReducer(dashboardReducer, initialDashboardState);

  return (
    <VStack w="full" spacing={6}>
      <SubscriptionSection
        onCancelSubscription={(subscription) =>
          dispatch({
            type: DashboardActionKind.OPEN_CANCEL_SUBSCRIPTION_MODAL,
            subscription,
          })
        }
        onReactivateSubscription={(subscription) =>
          dispatch({
            type: DashboardActionKind.OPEN_REACTIVATE_SUBSCRIPTION_MODAL,
            subscription,
          })
        }
      />
      <InvoiceSection />
      <SubscriptionReactivateModal
        isOpen={state.modalOpened === DashboardModal.REACTIVATE_SUBSCRIPTION}
        onClose={() => dispatch({ type: DashboardActionKind.CLOSE_MODAL })}
        subscription={state.subscription}
      />
      <SubscriptionCancelModal
        isOpen={state.modalOpened === DashboardModal.CANCEL_SUBSCRIPTION}
        onClose={() => dispatch({ type: DashboardActionKind.CLOSE_MODAL })}
        subscription={state.subscription}
      />
    </VStack>
  );
};
