import { Stripe_InvoiceStatus } from "../graphql/generated/user-hooks";
import { TFunction } from "i18next";

export const mapInvoiceStatusToBadgeColor = (status: Stripe_InvoiceStatus) => {
  switch (status) {
    case Stripe_InvoiceStatus.Paid:
      return "green";
    case Stripe_InvoiceStatus.Open:
      return "red";
    case Stripe_InvoiceStatus.Uncollectible:
      return "red";
    default:
      return "gray";
  }
};

export const mapInvoiceStatusToText = (args: {
  t: TFunction;
  status: Stripe_InvoiceStatus;
}) => {
  const { t, status } = args;

  switch (args.status) {
    case Stripe_InvoiceStatus.Paid:
      return t("invoices.status.paid");
    case Stripe_InvoiceStatus.Open:
      return t("invoices.status.open");
    case Stripe_InvoiceStatus.Void:
      return t("invoices.status.void");
    case Stripe_InvoiceStatus.Draft:
      return t("invoices.status.draft");
    case Stripe_InvoiceStatus.Uncollectible:
      return t("invoices.status.uncollectible");
    default:
      throw new Error(`Unknown invoice status: ${status}`);
  }
};
