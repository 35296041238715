import {
  Box,
  CircularProgress,
  Divider,
  HStack,
  Icon,
  Stack,
  Tag,
  Text,
  useToast,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useUpdateEffect, useWindowsFocus } from "@reactuses/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInvoicesQuery } from "../../graphql/generated/user-hooks";
import { formatPrice } from "../../helpers/format-price";
import {
  mapInvoiceStatusToBadgeColor,
  mapInvoiceStatusToText,
} from "../../helpers/invoice-status";
import { useSource } from "../../helpers/use-source";
import { Button } from "../../components/chackra-ui-button";

export const InvoiceSection = () => {
  const source = useSource();
  const focused = useWindowsFocus();
  const { t } = useTranslation();
  const toast = useToast();
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const { data, loading, fetchMore, refetch } = useInvoicesQuery({
    fetchPolicy: "network-only",
    context: { endpoint: "fitnext" },
    onError: (error) => {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    setFetchMoreLoading(false);
  }, [data?.invoices.edges]);

  useUpdateEffect(() => {
    if (focused) {
      refetch();
    }
  }, [focused]);

  const dynamicProps =
    source === "app"
      ? {}
      : { borderWidth: 1, borderRadius: 8, shadow: "sm", p: 6 };

  return (
    <Box w="full" pb={data && data.invoices.edges.length > 0 ? 2 : 6}>
      <Text fontSize="2xl" fontWeight="600" color="gray.900" mb={8}>
        {t("invoices.title")}
      </Text>
      <VStack w="full" spacing={4} mt={8} mb={4} align="start">
        {loading || !data ? (
          <HStack>
            <CircularProgress isIndeterminate color="blue.500" size={4} />
            <Text>{t("invoices.loading")}</Text>
          </HStack>
        ) : data.invoices.edges.length > 0 ? (
          <VStack w="full" spacing={4}>
            {data.invoices.edges.map(({ node }, index) => (
              <VStack key={node.id} w="full" spacing={4}>
                <Stack
                  key={node.id}
                  direction={{ base: "column", md: "row" }}
                  justify="space-between"
                  w="full"
                  spacing={4}
                >
                  <Wrap spacing={{ base: 4, sm: 8 }} justify="space-between">
                    <HStack spacing={{ base: 4, md: 8 }}>
                      <Text fontWeight={500}>
                        {dayjs(node.createdAt).format("DD/MM/YYYY")}
                      </Text>
                      <Text w={{ base: "auto", sm: 20 }}>
                        {formatPrice({
                          t,
                          amount: node.amountDue,
                        })}
                      </Text>
                    </HStack>
                    {node.status ? (
                      <Box display="flex" justifyContent="center">
                        <Tag
                          colorScheme={mapInvoiceStatusToBadgeColor(
                            node.status
                          )}
                        >
                          {mapInvoiceStatusToText({ t, status: node.status })}
                        </Tag>
                      </Box>
                    ) : null}
                  </Wrap>
                  <Box
                    display="flex"
                    justifyContent={{ base: "flex-start", md: "flex-end" }}
                    flex={1}
                  >
                    {node.hostedInvoiceUrl ? (
                      <Button
                        {...(source === "kiosk" || source === "app"
                          ? {
                              onClick: () => {
                                (window as any).invoiceHandler?.postMessage(
                                  node.hostedInvoiceUrl
                                );
                              },
                            }
                          : {
                              href: node.hostedInvoiceUrl,
                              target: "_blank",
                              as: "a",
                            })}
                        variant="link"
                        colorScheme="blue"
                        fontWeight={500}
                        textDecoration="underline"
                        textAlign="end"
                        w="auto"
                        borderRadius={4}
                        rightIcon={
                          <Icon h={4} w={4}>
                            <ArrowTopRightOnSquareIcon />
                          </Icon>
                        }
                      >
                        {t("invoices.viewInvoice")}
                      </Button>
                    ) : null}
                  </Box>
                </Stack>
                {index < data.invoices.edges.length - 1 ? <Divider /> : null}
              </VStack>
            ))}
            {data.invoices.pageInfo.hasNextPage ? (
              <Button
                mt={6}
                w="full"
                variant="outline"
                isLoading={fetchMoreLoading}
                onClick={() => {
                  setFetchMoreLoading(true);
                  fetchMore({
                    variables: { after: data.invoices.pageInfo.endCursor },

                    updateQuery: (previousResult, { fetchMoreResult }) => {
                      if (!fetchMoreResult) {
                        return previousResult;
                      }

                      const previousEdges = previousResult.invoices.edges;
                      const fetchMoreEdges = fetchMoreResult.invoices.edges;

                      fetchMoreResult.invoices.edges = [
                        ...previousEdges,
                        ...fetchMoreEdges,
                      ];

                      return { ...fetchMoreResult };
                    },
                  });
                }}
              >
                {t("invoices.loadMore")}
              </Button>
            ) : null}
          </VStack>
        ) : (
          <Text>{t("invoices.noInvoices")}</Text>
        )}
      </VStack>
    </Box>
  );
};
