import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../auth/auth-context/auth-context";
import { Routes } from "../../helpers/router";
import { AppContainer } from "../app-container";
import { LoadingScreen } from "../loading-screen";

export const ProtectedRoutes = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadingScreen />;
  }

  if (user) {
    return <Navigate to={Routes.Dashboard()} />;
  }

  return (
    <AppContainer>
      <Outlet />
    </AppContainer>
  );
};
