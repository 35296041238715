const addSearchParams = (
  url: string,
  searchParams?: Record<string, string>
) => {
  if (searchParams && Object.keys(searchParams).length > 0) {
    const filteredParams = Object.fromEntries(
      Object.entries(searchParams).filter(([_, value]) => value !== "")
    );
    if (Object.keys(filteredParams).length > 0) {
      const queryString = new URLSearchParams(filteredParams).toString();
      return `${url}?${queryString}`;
    }
  }
  return url;
};

export const Routes = {
  Dashboard: () => "/",
  Login: () => "/login",
  Register: () => "/register",
  Profiles: () => "/profiles",
  CreateProfile: () => "/create-profile",
  Subscriptions: () => "/subscriptions",
  Credential: () => "/credential",
  Onboarding: {
    Index: () => "/onboarding",
    Profile: () => "/onboarding/profile",
    Plan: () => "/onboarding/plan",
    PaymentMethod: () => "/onboarding/payment-method",
    Card: () => "/onboarding/card",
    Code: () => "/onboarding/code",
    Terms: () => "/onboarding/terms",
  },
  UserManagement: () => "/usermgmt",
  ResetPassword: () => "/reset-password",
  SelectedQuote: () => "/quote",
};
