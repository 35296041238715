import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const productionConfig = {
  apiKey: "AIzaSyDM2hgDN9G0JmGWZQsQ4193PW_6AhcDOLs",
  authDomain: "com-clubsensei.firebaseapp.com",
  databaseURL: "https://com-clubsensei.firebaseio.com",
  projectId: "com-clubsensei",
  storageBucket: "com-clubsensei.appspot.com",
  messagingSenderId: "688553086678",
  appId: "1:688553086678:web:2e76d6c996e6cb4c734377",
  measurementId: "G-0PR33552FM",
};

const developmentConfig = {
  apiKey: "AIzaSyBTOn9WGUj_dpPoLDpFHIX4FDAnQtfqw8A",
  authDomain: "com-clubsensei-dev.firebaseapp.com",
  databaseURL:
    "https://com-clubsensei-dev-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "com-clubsensei-dev",
  storageBucket: "com-clubsensei-dev.appspot.com",
  messagingSenderId: "384155161887",
  appId: "1:384155161887:web:836cf88a1d4caf61b84c2a",
  measurementId: "G-QX16WQ41TV",
};

const config =
  import.meta.env.VITE_FIREBASE_PROJECT_ID === "com-clubsensei"
    ? productionConfig
    : developmentConfig;

// Initialize Firebase
const app = initializeApp(config);
export const auth = getAuth(app);
