import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type SourceType = "kiosk" | "app" | null;

export const useSource = (): SourceType => {
  const [searchParams] = useSearchParams();
  const [source, setSource] = useState<SourceType>(() => {
    const paramSource = searchParams.get("source") as SourceType;
    const validSource =
      paramSource === "kiosk" || paramSource === "app" ? paramSource : null;
    return (
      validSource || (sessionStorage.getItem("source") as SourceType) || null
    );
  });

  useEffect(() => {
    const paramSource = searchParams.get("source") as SourceType;
    if (paramSource === "kiosk" || paramSource === "app") {
      sessionStorage.setItem("source", paramSource);
      setSource(paramSource);
    }
  }, [searchParams]);

  return source;
};
