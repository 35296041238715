import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  GridItem,
  Input,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DateSelect } from "../../../components/date-select";
import { PhoneInput } from "../../../components/phone-input";
import { ProfileSex } from "../../../graphql/generated/user-hooks";
import { countries as countriesData } from "../../../helpers/countries";
import { PersonalDetailsFormValues } from "./personal-details";
dayjs.extend(customParseFormat);

export const ProfileForm = () => {
  const { t, i18n } = useTranslation();
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<PersonalDetailsFormValues>();

  const dateOfBirth = watch("dateOfBirth");
  // Needs to clone it to avoid mutating the original array
  const countries = [...countriesData];

  const isUnderage =
    dayjs(dateOfBirth, "YYYY-MM-DD", true).isValid() &&
    dayjs().diff(dateOfBirth, "years") < 18;

  return (
    <SimpleGrid columns={{ base: 1, md: 12 }} spacing={6}>
      <GridItem colSpan={{ base: 1, md: 6 }}>
        <FormControl isInvalid={!!errors.firstName}>
          <FormLabel htmlFor="firstName">
            {t("personalDetails.form.firstName")}
          </FormLabel>
          <Input
            id="firstName"
            size="lg"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            {...register("firstName")}
          />
          {!!errors.firstName && errors.firstName?.message ? (
            <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 1, md: 6 }}>
        <FormControl isInvalid={!!errors.lastName}>
          <FormLabel htmlFor="lastName">
            {t("personalDetails.form.lastName")}
          </FormLabel>
          <Input
            id="lastName"
            size="lg"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            {...register("lastName")}
          />
          {!!errors.lastName && errors.lastName?.message ? (
            <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 1, md: 6 }}>
        <FormControl isInvalid={!!errors.identityNumber}>
          <FormLabel htmlFor="identityNumber">
            {t("personalDetails.form.identityNumber")}
          </FormLabel>
          <Input
            id="identityNumber"
            size="lg"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            {...register("identityNumber")}
          />
          {!!errors.identityNumber && errors.identityNumber?.message ? (
            <FormErrorMessage>{errors.identityNumber.message}</FormErrorMessage>
          ) : (
            <FormHelperText>
              {t("personalDetails.form.identityNumberOptional")}
            </FormHelperText>
          )}
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 1, md: 6 }}>
        <FormControl isInvalid={!!errors.dateOfBirth}>
          <FormLabel htmlFor="dateOfBirth">
            {t("personalDetails.form.dateOfBirth")}
          </FormLabel>
          <DateSelect
            id="dateOfBirth"
            onChange={(value) => {
              setValue("dateOfBirth", value);
              clearErrors("dateOfBirth");
              clearErrors("identityNumber");
            }}
            value={watch("dateOfBirth")}
          />
          {!!errors.dateOfBirth && errors.dateOfBirth?.message ? (
            <FormErrorMessage>{errors.dateOfBirth.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </GridItem>

      <GridItem colSpan={{ base: 1, md: 6 }}>
        <FormControl isInvalid={!!errors.phoneNumber}>
          <FormLabel htmlFor="phoneNumber">
            {t("personalDetails.form.phoneNumber")}
          </FormLabel>
          <PhoneInput
            id="phoneNumber"
            onChange={(value) => {
              setValue("phoneNumber", value);
              clearErrors("phoneNumber");
            }}
          />
          {!!errors.phoneNumber && errors.phoneNumber?.message ? (
            <FormErrorMessage>{errors.phoneNumber.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </GridItem>

      <GridItem colSpan={{ base: 1, md: 6 }}>
        <FormControl isInvalid={!!errors.sex}>
          <FormLabel htmlFor="sex">{t("personalDetails.form.sex")}</FormLabel>
          <Select
            id="sex"
            size="lg"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            {...register("sex")}
          >
            <option value={ProfileSex.Male}>
              {t("personalDetails.form.sexOptions.male")}
            </option>
            <option value={ProfileSex.Female}>
              {t("personalDetails.form.sexOptions.female")}
            </option>
            <option value={ProfileSex.NotSpecified}>
              {t("personalDetails.form.sexOptions.notSpecified")}
            </option>
          </Select>
          {!!errors.sex && errors.sex?.message ? (
            <FormErrorMessage>{errors.sex.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        {/* <FormControl isInvalid={!!errors.sex}>
          <FormLabel htmlFor="sex">Sexo</FormLabel>
          <RadioGroup
            onChange={(value) => setValue("sex", value)}
            value={watch("sex")}
          >
            <Stack direction="row">
              <Radio value={Profile_Sex_Enum.Male}>Hombre</Radio>
              <Radio value={Profile_Sex_Enum.Female}>Mujer</Radio>
              <Radio value={Profile_Sex_Enum.Other}>No especificar</Radio>
            </Stack>
          </RadioGroup>
          {!!errors.sex && errors.sex?.message ? (
            <FormErrorMessage>{errors.sex.message}</FormErrorMessage>
          ) : null}
        </FormControl> */}
      </GridItem>
      <GridItem colSpan={{ base: 1, md: 12 }}>
        <FormControl isInvalid={!!errors.address}>
          <FormLabel htmlFor="address">
            {t("personalDetails.form.address")}
          </FormLabel>
          <Input
            id="address"
            size="lg"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            {...register("address")}
          />
          {!!errors.address && errors.address?.message ? (
            <FormErrorMessage>{errors.address.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 1, md: 4 }}>
        <FormControl isInvalid={!!errors.zipCode}>
          <FormLabel htmlFor="zipCode">
            {t("personalDetails.form.zipCode")}
          </FormLabel>
          <Input
            id="zipCode"
            size="lg"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            {...register("zipCode")}
          />
          {!!errors.zipCode && errors.zipCode?.message ? (
            <FormErrorMessage>{errors.zipCode.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 1, md: 4 }}>
        <FormControl isInvalid={!!errors.city}>
          <FormLabel htmlFor="city">{t("personalDetails.form.city")}</FormLabel>
          <Input
            id="city"
            size="lg"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            {...register("city")}
          />
          {!!errors.city && errors.city?.message ? (
            <FormErrorMessage>{errors.city.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 1, md: 4 }}>
        <FormControl isInvalid={!!errors.country}>
          <FormLabel htmlFor="country">
            {t("personalDetails.form.country")}
          </FormLabel>
          <Select
            id="country"
            size="lg"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            {...register("country")}
          >
            {countries
              .sort((a, b) => {
                if (i18n.language.startsWith("en")) {
                  return a.name_en.localeCompare(b.name_en);
                } else {
                  return a.name_es.localeCompare(b.name_es);
                }
              })
              .map((country) => (
                <option key={country.code} value={country.code}>
                  {i18n.language.startsWith("en")
                    ? country.name_en
                    : country.name_es}
                </option>
              ))}
          </Select>
          {!!errors.country && errors.country?.message ? (
            <FormErrorMessage>{errors.country.message}</FormErrorMessage>
          ) : null}
        </FormControl>
      </GridItem>
    </SimpleGrid>
  );
};
