import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  HStack,
  Tag,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useUpdateEffect, useWindowsFocus } from "@reactuses/core";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSubscriptionsQuery } from "../../graphql/generated/user-hooks";
import { formatPrice } from "../../helpers/format-price";
import {
  mapSubscriptionStatusToBadgeColor,
  mapSubscriptionStatusToText,
} from "../../helpers/subscription-status";
import { useSource } from "../../helpers/use-source";
import { AccessCredential } from "./access-credential";
import { Button } from "../../components/chackra-ui-button";

type Props = {
  onCancelSubscription: (subscription: {
    id: string;
    currentPeriodEnd: Date;
  }) => void;
  onReactivateSubscription: (subscription: {
    id: string;
    currentPeriodEnd: Date;
  }) => void;
};

export const SubscriptionSection = (props: Props) => {
  const focused = useWindowsFocus();
  const { i18n, t } = useTranslation();
  const currentLocale = i18n.language;
  const toast = useToast();
  const { data, loading, refetch } = useSubscriptionsQuery({
    fetchPolicy: "network-only",
    context: { endpoint: "fitnext" },
    onError: (error) => {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
      });
    },
  });

  useUpdateEffect(() => {
    if (focused) {
      refetch();
    }
  }, [focused]);

  return (
    <Box w="full">
      <HStack
        flexWrap="wrap"
        justify="space-between"
        alignItems="center"
        mb={8}
      >
        <Text fontSize="2xl" fontWeight={700} color="gray.900">
          {t("subscriptions.title")}
        </Text>
        {data && data.subscriptions.edges.length > 0 ? (
          <AccessCredential />
        ) : null}
      </HStack>
      <VStack w="full" spacing={4} mt={8} mb={4} align="start">
        {loading || !data ? (
          <HStack>
            <CircularProgress isIndeterminate color="blue.500" size={4} />
            <Text>{t("subscriptions.loading")}</Text>
          </HStack>
        ) : data.subscriptions.edges.length > 0 ? (
          <VStack w="full" spacing={4}>
            {data.subscriptions.edges.map(({ node }, index) => {
              const amount = node.items.reduce(
                (acc, curr) => acc + (curr.price.unitAmount ?? 0),
                0
              );

              const isPackage = node.items.some(
                (i) => i.price.metadata.type === "package"
              );

              return (
                <VStack key={node.id} w="full" align="start" spacing={3}>
                  <HStack>
                    <Tag
                      colorScheme={mapSubscriptionStatusToBadgeColor(
                        node.status
                      )}
                    >
                      {mapSubscriptionStatusToText({ t, status: node.status })}
                    </Tag>
                    {node.cancelAt ? (
                      <Tag colorScheme="gray">
                        {t("subscriptions.endsOn")}{" "}
                        {dayjs(node.cancelAt)
                          .locale(currentLocale)
                          .subtract(1, "day")
                          .format("DD MMM. YYYY")}
                      </Tag>
                    ) : null}
                  </HStack>
                  <VStack align="start" spacing={0.5}>
                    <Text fontSize="lg" fontWeight={500}>
                      {node.items
                        .map(
                          (i) =>
                            i.price.product.metadata[
                              `locale_${currentLocale}`
                            ] ?? i.price.product.name
                        )
                        .join(", ")}
                    </Text>
                    <Text>
                      {formatPrice({
                        t,
                        amount,
                        intervalCount:
                          node.items[0].price.recurring?.intervalCount,
                        interval: node.items[0].price.recurring?.interval,
                      })}
                    </Text>
                  </VStack>
                  {node.upcomingInvoice ? (
                    <Text fontSize="sm" color="gray.500">
                      {t("subscriptions.nextCharge")}{" "}
                      {dayjs(node.upcomingInvoice.createdAt)
                        .locale(currentLocale)
                        .format("DD MMM. YYYY")}{" "}
                      {t("subscriptions.of")}{" "}
                      {formatPrice({
                        t,
                        amount: node.upcomingInvoice.amountRemaining,
                      })}
                    </Text>
                  ) : null}
                  {node.canceledAt && node.cancelAt && !isPackage ? (
                    <Alert fontSize="sm" colorScheme="orange">
                      {t("subscriptions.canceledOn")}{" "}
                      {dayjs(node.canceledAt)
                        .locale(currentLocale)
                        .format("DD MMM. YYYY")}{" "}
                      {t("subscriptions.at")}{" "}
                      {dayjs(node.canceledAt)
                        .locale(currentLocale)
                        .format("HH:mm")}
                      , {t("subscriptions.activeUntil")}{" "}
                      {dayjs(node.cancelAt)
                        .subtract(1, "day")
                        .locale(currentLocale)
                        .format("DD MMM. YYYY")}
                      .
                    </Alert>
                  ) : null}
                  {isPackage ? null : node.cancelAt ? (
                    <Button
                      colorScheme="blue"
                      borderRadius={4}
                      onClick={() =>
                        props.onReactivateSubscription({
                          id: node.id,
                          currentPeriodEnd: node.currentPeriodEnd,
                        })
                      }
                      w={{ base: "100%", md: "auto" }}
                    >
                      {t("subscriptions.reactivateSubscription")}
                    </Button>
                  ) : (
                    <Button
                      variant="outline"
                      borderRadius={4}
                      onClick={() =>
                        props.onCancelSubscription({
                          id: node.id,
                          currentPeriodEnd: node.currentPeriodEnd,
                        })
                      }
                      w={{ base: "100%", md: "auto" }}
                    >
                      {t("subscriptions.cancelSubscription")}
                    </Button>
                  )}

                  {index < data.subscriptions.edges.length - 1 ? (
                    <Divider />
                  ) : null}
                </VStack>
              );
            })}
          </VStack>
        ) : (
          <Text>{t("subscriptions.noSubscriptions")}</Text>
        )}
      </VStack>
    </Box>
  );
};

{
  /* <Box w="full" bg="white" p={6} borderRadius={8} shadow="sm" borderWidth={1}>
<Text fontSize="2xl" fontWeight="600" color="gray.900">
  Suscripciones
</Text>
<VStack w="full" spacing={4} mt={8} mb={4} align="start">
  {data.profile.customers.map((customer, customerIndex) => {
    return (
      <React.Fragment key={customer.id}>
        {data.profile &&
        data.profile.customers &&
        data.profile.customers.length > 1 ? (
          <Text
            fontSize="xs"
            fontWeight={600}
            textTransform="uppercase"
            color="gray"
            pt={customerIndex > 0 ? 4 : 0}
          >
            {mapStripeAccountToString(customer.stripe_account)}
          </Text>
        ) : null}
        {customer.subscriptions.length > 0 ? (
          customer.subscriptions
            .filter(
              (sub) =>
                sub.status !== "canceled" &&
                sub.status !== "incomplete_expired"
            )
            .map((sub, index) => {
              const amount = sub.prices.reduce(
                (acc, curr) => acc + (curr.price?.unit_amount ?? 0),
                0
              );
              return (
                <React.Fragment key={sub.id}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    justifyContent={{ sm: "space-between" }}
                    alignItems={{ sm: "center" }}
                    w="full"
                  >
                    <VStack align="start" spacing={1}>
                      <HStack>
                        <Text fontWeight={500}>
                          {sub.prices
                            .map((i) => i.price?.product?.name)
                            .join(", ")}
                        </Text>
                        <Badge
                          colorScheme={
                            sub.cancel_at
                              ? mapSubscriptionStatusToBadgeColor(
                                  "canceled"
                                )
                              : mapSubscriptionStatusToBadgeColor(
                                  sub.status
                                )
                          }
                        >
                          {sub.cancel_at
                            ? mapSubscriptionStatusToText("canceled")
                            : mapSubscriptionStatusToText(sub.status)}
                        </Badge>
                      </HStack>
                      <Text fontSize="lg">
                        {formatPriceInterval(
                          amount,
                          sub.prices[0]?.price?.interval_count,
                          sub.prices[0]?.price?.interval
                        )}
                      </Text>
                      <Text fontSize="sm">
                        {sub.cancel_at
                          ? `Tu suscripción finaliza el ${dayjs(
                              sub.cancel_at
                            )
                              .locale("es")
                              .format("DD MMM. YYYY")}`
                          : `Tu suscripción renueva el ${dayjs(
                              sub.current_period_end
                            )
                              .locale("es")
                              .format("DD MMM. YYYY")}`}
                      </Text>
                      {sub.default_card ? (
                        <HStack alignItems="center">
                          <Image
                            h={4}
                            src={
                              sub.default_card.brand === "visa"
                                ? visaCard
                                : sub.default_card.brand === "mastercard"
                                ? mastercardCard
                                : genericCard
                            }
                          />
                          <Text fontSize="sm" textTransform="uppercase">
                            {sub.default_card.brand} ••••{" "}
                            {sub.default_card.last4} -{" "}
                            {sub.default_card.exp_month}/
                            {sub.default_card.exp_year}
                          </Text>
                        </HStack>
                      ) : null}
                    </VStack>
                    {sub.cancel_at ? (
                      <Button
                        colorScheme="blue"
                        borderRadius={4}
                        onClick={() =>
                          setReactivate({
                            id: sub.id,
                            stripeAccount: sub.stripe_account,
                          })
                        }
                      >
                        Reactivar
                      </Button>
                    ) : (
                      <Button
                        variant="outline"
                        borderRadius={4}
                        onClick={() =>
                          setCancel({
                            id: sub.id,
                            stripeAccount: sub.stripe_account,
                          })
                        }
                      >
                        Cancelar
                      </Button>
                    )}
                  </Stack>
                  {index !==
                  customer.subscriptions.filter(
                    (sub) =>
                      sub.status !== "canceled" &&
                      sub.status !== "incomplete_expired"
                  ).length -
                    1 ? (
                    <Divider />
                  ) : null}
                </React.Fragment>
              );
            })
        ) : (
          <Text mb={4}>No existen suscripciones</Text>
        )}
      </React.Fragment>
    );
  })}
</VStack>
</Box>; */
}
