import { Container, Divider, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Routes } from "../../helpers/router";
import { useSource } from "../../helpers/use-source";
import { LoginEmailForm } from "./login-email-form";

export const Login = () => {
  const source = useSource();
  const { t } = useTranslation();

  return (
    <Container
      maxW="md"
      display="flex"
      flexDirection="column"
      flex={1}
      justifyContent="center"
      alignItems="stretch"
    >
      <Heading size="lg" textAlign="center" mb={12}>
        {t("login.title")}
      </Heading>
      <LoginEmailForm />
      {source === "kiosk" ? (
        <div>
          <Divider my={8} color="gray.500" />
          <Text textAlign="center">
            {t("login.noAccount")}{" "}
            <NavLink to={Routes.Register()}>
              <Link as="span" color="blue.500">
                {t("login.signUp")}
              </Link>
            </NavLink>
          </Text>
        </div>
      ) : null}
    </Container>
  );
};
