import { onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useReducer } from "react";
import { LoadingScreen } from "../../components/loading-screen";
import { auth } from "../config";

import { AuthActionKind, authReducer, AuthState } from "./auth-context.reducer";
import * as Sentry from "@sentry/react";

const initialState: AuthState = {
  user: null,
  loading: true,
};

export const authContext = createContext<AuthState>(initialState);

export type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = (props: AuthProviderProps) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        dispatch({ type: AuthActionKind.LOGGED_IN, user });
        Sentry.setUser({ id: user.uid });
      } else {
        dispatch({ type: AuthActionKind.LOGGED_OUT });
        Sentry.setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (state.loading) {
    return <LoadingScreen />;
  }

  return (
    <authContext.Provider value={state}>{props.children}</authContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(authContext);
  return { ...context };
};
