import { Stripe_SubscriptionStatus } from "../graphql/generated/user-hooks";
import { TFunction } from "i18next";

export const mapSubscriptionStatusToBadgeColor = (
  status: Stripe_SubscriptionStatus
) => {
  switch (status) {
    case Stripe_SubscriptionStatus.Incomplete:
      return "orange";
    case Stripe_SubscriptionStatus.Trialing:
      return "purple";
    case Stripe_SubscriptionStatus.Active:
      return "green";
    case Stripe_SubscriptionStatus.PastDue:
      return "red";
    case Stripe_SubscriptionStatus.Unpaid:
      return "red";
    default:
      return "gray";
  }
};

export const mapSubscriptionStatusToText = (args: {
  t: TFunction;
  status: Stripe_SubscriptionStatus;
}) => {
  const { t, status } = args;
  switch (status) {
    case Stripe_SubscriptionStatus.Incomplete:
      return t("subscriptions.status.incomplete");
    case Stripe_SubscriptionStatus.IncompleteExpired:
      return t("subscriptions.status.incompleteExpired");
    case Stripe_SubscriptionStatus.Trialing:
      return t("subscriptions.status.trialing");
    case Stripe_SubscriptionStatus.Active:
      return t("subscriptions.status.active");
    case Stripe_SubscriptionStatus.PastDue:
      return t("subscriptions.status.pastDue");
    case Stripe_SubscriptionStatus.Canceled:
      return t("subscriptions.status.canceled");
    case Stripe_SubscriptionStatus.Unpaid:
      return t("subscriptions.status.unpaid");
    case Stripe_SubscriptionStatus.Paused:
      return t("subscriptions.status.paused");
    default:
      return "-";
  }
};
