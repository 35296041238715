import { ProfileSex } from "../../../graphql/generated/user-hooks";

export const mapProfileSex = (sex: string): ProfileSex => {
  switch (sex.toUpperCase()) {
    case "MALE":
      return ProfileSex.Male;
    case "FEMALE":
      return ProfileSex.Female;
    default:
      return ProfileSex.NotSpecified;
  }
};
