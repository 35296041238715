import { signOut } from "firebase/auth";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AppContainer } from "../app-container";
import { LoadingScreen } from "../loading-screen";
import { useAuth } from "../../auth/auth-context/auth-context";
import { auth } from "../../auth/config";

export const UnauthRoutes = () => {
  const { user, loading } = useAuth();

  useEffect(() => {
    signOut(auth);
  }, []);

  if (loading || user) {
    return <LoadingScreen />;
  }

  return (
    <AppContainer>
      <Outlet />
    </AppContainer>
  );
};
