import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useCancelSubscriptionMutation } from "../../graphql/generated/user-hooks";
import { Button } from "../../components/chackra-ui-button";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  subscription: {
    id: string;
    currentPeriodEnd: Date;
  } | null;
};

export const SubscriptionCancelModal = (props: Props) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [cancelSubscription, { loading }] = useCancelSubscriptionMutation({
    context: { endpoint: "fitnext" },
    refetchQueries: ["Subscriptions"],
    onError: (error) => {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
      });
    },
  });

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent m={6}>
        <ModalCloseButton onClick={props.onClose} />
        <ModalHeader>
          <Text>{t("cancelModal.title")}</Text>
        </ModalHeader>
        <ModalBody>
          <VStack>
            <Text>
              {t("cancelModal.message")}{" "}
              {dayjs(props.subscription?.currentPeriodEnd)
                .subtract(1, "day")
                .format("DD/MM/YYYY")}
              .
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button borderRadius={4} onClick={props.onClose}>
              {t("cancelModal.close")}
            </Button>
            <Button
              disabled={!props.subscription}
              borderRadius={4}
              colorScheme="red"
              isLoading={loading}
              onClick={() => {
                if (!props.subscription) return;

                cancelSubscription({
                  variables: { id: props.subscription.id },
                }).finally(() => {
                  props.onClose();
                });
              }}
            >
              {t("cancelModal.confirm")}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
