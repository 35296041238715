import { Box, CircularProgress } from "@chakra-ui/react";

export const ContainerLoading = () => {
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress isIndeterminate color="blue.500" />
    </Box>
  );
};
