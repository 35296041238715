import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { useAuth } from "../../auth/auth-context/auth-context";
import {
  useInvoicesGuardQuery,
  useWhoAmIQuery,
} from "../../graphql/generated/user-hooks";
import { Routes } from "../../helpers/router";
import { PendingDraft } from "../../views/dashboard/pending-draft";
import { PendingPayment } from "../../views/dashboard/pending-payment";
import { AppContainer } from "../app-container";
import { ContainerLoading } from "../container-loading";
import { LoadingScreen } from "../loading-screen";

const POLL_INTERVAL = 5000; // 5 seconds

export const ProfileRoutes = () => {
  const [searchParams] = useSearchParams();
  const { user, loading: authLoading } = useAuth();

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    fetchPolicy: "network-only",
    context: { endpoint: "fitnext" },
  });

  const { data: invoicesData, loading: invoicesLoading } =
    useInvoicesGuardQuery({
      fetchPolicy: "network-only",
      context: { endpoint: "fitnext" },
      pollInterval: POLL_INTERVAL,
      skip: !whoAmIData?.whoAmI.profile?.id,
    });

  if (authLoading) {
    return <LoadingScreen />;
  }

  if (!user) {
    return <Navigate to={Routes.Login()} />;
  }

  if (whoAmILoading) {
    return (
      <AppContainer>
        <ContainerLoading />
      </AppContainer>
    );
  }

  const profile = whoAmIData?.whoAmI.profile;

  if (!profile) {
    return (
      <Navigate
        to={Routes.Profiles()}
        state={{ search: searchParams.toString() }}
      />
    );
  }

  if (invoicesLoading) {
    return (
      <AppContainer
        profile={{ avatarUrl: profile.avatarUrl, name: profile.firstName }}
      >
        <ContainerLoading />
      </AppContainer>
    );
  }

  if (invoicesData && invoicesData.openInvoices.edges.length > 0) {
    const invoice = invoicesData.openInvoices.edges[0].node;
    return (
      <AppContainer
        profile={{ avatarUrl: profile.avatarUrl, name: profile.firstName }}
      >
        <PendingPayment
          hostedInvoiceUrl={invoice.hostedInvoiceUrl ?? undefined}
          amountRemaining={invoice.amountRemaining}
        />
      </AppContainer>
    );
  }

  if (invoicesData && invoicesData.draftInvoices.edges.length > 0) {
    return (
      <AppContainer
        profile={{ avatarUrl: profile.avatarUrl, name: profile.firstName }}
      >
        <PendingDraft />
      </AppContainer>
    );
  }

  return (
    <AppContainer
      profile={{ avatarUrl: profile.avatarUrl, name: profile.firstName }}
    >
      <Outlet />
    </AppContainer>
  );
};
