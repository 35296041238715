import React from "react";
import { useSource } from "../../helpers/use-source";
import { Icon } from "@chakra-ui/react";
import { QrCodeIcon } from "@heroicons/react/24/outline";
import { useAccessCredentialLazyQuery } from "../../graphql/generated/user-hooks";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../helpers/router";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/chackra-ui-button";

export const AccessCredential = () => {
  const { t } = useTranslation();
  const source = useSource();
  const navigate = useNavigate();

  const [getToken, { loading }] = useAccessCredentialLazyQuery({
    fetchPolicy: "network-only",
    context: { endpoint: "fitnext" },
    onCompleted: (data) => {
      (window as any).credentialHandler?.postMessage(data.accessCredential);
    },
  });

  if (source === "kiosk") {
    return (
      <Button
        colorScheme="blue"
        onClick={() => getToken()}
        borderRadius={4}
        isLoading={loading}
        leftIcon={
          <Icon h={5} w={5}>
            <QrCodeIcon />
          </Icon>
        }
      >
        {t("printQR")}
      </Button>
    );
  } else {
    return (
      <Button
        colorScheme="blue"
        onClick={() => navigate(Routes.Credential())}
        borderRadius={4}
        leftIcon={
          <Icon h={5} w={5}>
            <QrCodeIcon />
          </Icon>
        }
      >
        QR
      </Button>
    );
  }
};
