import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { auth } from "./auth/config";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const fitnextHttpLink = createHttpLink({
  uri: `${import.meta.env.VITE_FITNEXT_ENDPOINT}/v1/graphql/user`,
});

const clientAuthLink = () =>
  setContext(async (_, { headers }) => {
    const authToken = await auth.currentUser?.getIdToken();
    const language = localStorage.getItem("i18nextLng") ?? "es";

    return {
      headers: {
        ...headers,
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
        "x-hasura-role": "user",
        "Accept-Language": language,
      },
    };
  });

export const apolloClient = new ApolloClient({
  link: clientAuthLink().concat(from([errorLink, fitnextHttpLink])),
  cache: new InMemoryCache(),
});
