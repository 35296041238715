import { Container, Divider, Heading, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Navigate, NavLink } from "react-router-dom";
import { Routes } from "../../helpers/router";
import { useSource } from "../../helpers/use-source";
import { RegisterEmailForm } from "./register-email-form";

export const Register = () => {
  const { t } = useTranslation();
  const source = useSource();

  if (source !== "kiosk") {
    return <Navigate to={Routes.Login()} replace />;
  }

  return (
    <Container maxW="md">
      <Heading size="lg" textAlign="center" mb={16}>
        {t("register.createAccount")}
      </Heading>
      <RegisterEmailForm />
      <Divider my={8} color="gray.500" />
      <Text textAlign="center">
        {t("register.alreadyHaveAccount")}{" "}
        <NavLink to={Routes.Login()}>
          <Link as="span" color="blue.500">
            {t("register.loginNow")}
          </Link>
        </NavLink>
      </Text>
    </Container>
  );
};
