import { TFunction } from "i18next";
import { Stripe_PriceInterval } from "../graphql/generated/user-hooks";

const amountToString = (args: { t: TFunction; amount: number }) => {
  const { amount, t } = args;

  const amountStr = (amount / 100).toFixed(2).replace(".", ",");
  return `${amountStr}${t("formatPrice.currency")}`;
};

const formatInterval = (args: {
  t: TFunction;
  intervalCount: number;
  interval: Stripe_PriceInterval;
}) => {
  const { t, intervalCount, interval } = args;
  if (intervalCount === 1) {
    if (interval === Stripe_PriceInterval.Year) return t("formatPrice.year");
    if (interval === Stripe_PriceInterval.Month) return t("formatPrice.month");
    if (interval === Stripe_PriceInterval.Week) return t("formatPrice.week");
    return t("formatPrice.day");
  }

  if (interval === Stripe_PriceInterval.Year)
    return `${intervalCount} ${t("formatPrice.years")}`;
  if (interval === Stripe_PriceInterval.Month)
    return `${intervalCount} ${t("formatPrice.months")}`;
  if (interval === Stripe_PriceInterval.Week)
    return `${intervalCount} ${t("formatPrice.weeks")}`;
  return `${intervalCount} ${t("formatPrice.days")}`;
};

export const formatPrice = (args: {
  t: TFunction;
  amount: number;
  intervalCount?: number | null;
  interval?: Stripe_PriceInterval | null;
}) => {
  const { t, amount, intervalCount, interval } = args;

  const amountStr = amountToString({ t, amount });
  if (intervalCount == null || interval == null) {
    return amountStr;
  } else {
    return `${amountStr} ${t("formatPrice.every")} ${formatInterval({
      t,
      intervalCount,
      interval,
    })}`;
  }
};
