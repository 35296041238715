import { Container } from "@chakra-ui/react";
import { ResetPasswordForm } from "./reset-password-form";

export const ResetPassword = () => {
  return (
    <Container
      maxW="md"
      display="flex"
      flexDirection="column"
      flex={1}
      justifyContent="center"
      alignItems="stretch"
    >
      <ResetPasswordForm />
    </Container>
  );
};
