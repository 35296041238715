import { Alert, Link, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const PendingDraft = () => {
  const { t } = useTranslation();

  return (
    <Alert status="warning">
      <VStack alignItems="start">
        <Text>{t("pendingDraft.message")}</Text>
        <Text>
          {t("pendingDraft.contactUs")}{" "}
          <Link fontWeight={500} href="mailto:facturacion@clubsensei.com">
            {t("pendingDraft.email")}
          </Link>
        </Text>
      </VStack>
    </Alert>
  );
};
