export enum DashboardActionKind {
  OPEN_CANCEL_SUBSCRIPTION_MODAL = "OPEN_CANCEL_SUBSCRIPTION_MODAL",
  OPEN_REACTIVATE_SUBSCRIPTION_MODAL = "OPEN_REACTIVATE_SUBSCRIPTION_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
}

type DashboardAction =
  | {
      type: DashboardActionKind.OPEN_CANCEL_SUBSCRIPTION_MODAL;
      subscription: {
        id: string;
        currentPeriodEnd: Date;
      };
    }
  | {
      type: DashboardActionKind.OPEN_REACTIVATE_SUBSCRIPTION_MODAL;
      subscription: {
        id: string;
        currentPeriodEnd: Date;
      };
    }
  | { type: DashboardActionKind.CLOSE_MODAL };

export enum DashboardModal {
  REACTIVATE_SUBSCRIPTION = "REACTIVATE_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",
}

export type DashboardState = {
  modalOpened: DashboardModal | null;
  subscription: {
    id: string;
    currentPeriodEnd: Date;
  } | null;
};

export const initialDashboardState: DashboardState = {
  modalOpened: null,
  subscription: null,
};

export const dashboardReducer = (
  state: DashboardState,
  action: DashboardAction
): DashboardState => {
  switch (action.type) {
    case DashboardActionKind.CLOSE_MODAL:
      return {
        ...state,
        modalOpened: null,
        subscription: null,
      };
    case DashboardActionKind.OPEN_REACTIVATE_SUBSCRIPTION_MODAL:
      return {
        ...state,
        modalOpened: DashboardModal.REACTIVATE_SUBSCRIPTION,
        subscription: action.subscription,
      };
    case DashboardActionKind.OPEN_CANCEL_SUBSCRIPTION_MODAL:
      return {
        ...state,
        modalOpened: DashboardModal.CANCEL_SUBSCRIPTION,
        subscription: action.subscription,
      };
    default:
      return state;
  }
};
