const senseiConfig = {
  production: {
    id: "cs01gt61x9auptg3240vcm2",
  },
  development: {
    id: "cs1y2jq000166tgnvnn5dg2",
  },
};

export const getTenantConfig = () => {
  const project = import.meta.env.VITE_FIREBASE_PROJECT_ID;
  switch (project) {
    case "com-clubsensei":
      return senseiConfig.production;
    case "com-clubsensei-dev":
      return senseiConfig.development;
    default:
      break;
  }

  throw new Error("Tenant not found");
};
