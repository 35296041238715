import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useReactivateSubscriptionMutation } from "../../graphql/generated/user-hooks";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/chackra-ui-button";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  subscription: { id: string; currentPeriodEnd: Date } | null;
};

export const SubscriptionReactivateModal = (props: Props) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [reactivateSubscription, { loading }] =
    useReactivateSubscriptionMutation({
      context: { endpoint: "fitnext" },
      refetchQueries: ["Subscriptions"],
      onError: (error) => {
        toast({
          title: error.message,
          status: "error",
          isClosable: true,
        });
      },
    });

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent m={6}>
        <ModalCloseButton onClick={props.onClose} />
        <ModalHeader>
          <Text>{t("reactivateModal.title")}</Text>
        </ModalHeader>
        <ModalBody>
          <Text>{t("reactivateModal.message")}</Text>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button borderRadius={4} onClick={props.onClose}>
              {t("reactivateModal.close")}
            </Button>
            <Button
              disabled={!props.subscription}
              borderRadius={4}
              colorScheme="blue"
              isLoading={loading}
              onClick={() => {
                if (!props.subscription) return;

                reactivateSubscription({
                  variables: { id: props.subscription.id },
                }).finally(() => {
                  props.onClose();
                });
              }}
            >
              {t("reactivateModal.confirm")}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
