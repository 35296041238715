import { signOut } from "firebase/auth";
import { useIdleTimer } from "react-idle-timer";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "./auth/auth-context/auth-context";
import { auth } from "./auth/config";
import { PrivateRoutes } from "./components/routing/private-routes";
import { ProfileRoutes } from "./components/routing/profile-routes";
import { ProtectedRoutes } from "./components/routing/protected-routes";
import { UnauthRoutes } from "./components/routing/unauth-routes";
import { Routes as RouterRoutes } from "./helpers/router";
import { useSource } from "./helpers/use-source";
import { Dashboard } from "./views/dashboard/dashboard";
import { Login } from "./views/login/login";
import { NotFound } from "./views/not-found/not-found";
import { Profiles } from "./views/profiles/profiles";
import { Register } from "./views/register/register";
import { ResetPassword } from "./views/reset-password/reset-password";
import { UserManagement } from "./views/usermgmt/usermgmt";
import { useTranslation } from "react-i18next";
import { Credential } from "./views/credential/credential";
import { useEffect } from "react";

function App() {
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const source = useSource();

  const onIdle = () => {
    signOut(auth).then(() => {
      if (source === "kiosk") {
        i18n.changeLanguage("es");
        document.documentElement.lang = "es";
      }
    });
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 10,
    disabled: !user && source !== "kiosk",
  });

  return (
    <div>
      <Routes>
        <Route element={<ProfileRoutes />}>
          <Route index element={<Dashboard />} />
          <Route path={RouterRoutes.Credential()} element={<Credential />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path={RouterRoutes.Profiles()} element={<Profiles />} />
        </Route>
        <Route element={<ProtectedRoutes />}>
          <Route path={RouterRoutes.Login()} element={<Login />} />
          <Route path={RouterRoutes.Register()} element={<Register />} />
        </Route>
        <Route element={<UnauthRoutes />}>
          <Route
            path={RouterRoutes.UserManagement()}
            element={<UserManagement />}
          />
          <Route
            path={RouterRoutes.ResetPassword()}
            element={<ResetPassword />}
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
