import {
  Alert,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../helpers/format-price";
import { Button } from "../../components/chackra-ui-button";

type Props = {
  hostedInvoiceUrl?: string | null;
  amountRemaining: number;
};

export const PendingPayment = (props: Props) => {
  const { t } = useTranslation();
  const [showRefresh, setShowRefresh] = useState(false);

  return (
    <VStack
      w="full"
      bg="white"
      p={6}
      borderRadius={8}
      shadow="sm"
      borderWidth={1}
      alignItems="start"
    >
      <HStack mb={8}>
        <Icon boxSize={8} color="red">
          <ExclamationCircleIcon />
        </Icon>
        <Text fontSize="2xl" fontWeight="600" color="gray.900">
          {t("pendingPayment.title")}
        </Text>
      </HStack>
      <VStack w="full" alignItems="start" spacing={6}>
        {showRefresh ? (
          <Alert status="info" borderRadius={4}>
            <Stack
              direction={{ base: "column", md: "row" }}
              alignItems={{ md: "center" }}
            >
              <Text>{t("pendingPayment.refreshMessage")}</Text>
              <Button
                borderRadius={4}
                onClick={() => window.location.reload()}
                leftIcon={
                  <Icon>
                    <ArrowPathIcon />
                  </Icon>
                }
              >
                {t("pendingPayment.refresh")}
              </Button>
            </Stack>
          </Alert>
        ) : null}
        <VStack w="full" alignItems="start">
          <Text>
            {t("pendingPayment.pendingPaymentOf")}{" "}
            <Text as="span" fontWeight={600}>
              {formatPrice({ t, amount: props.amountRemaining })}
            </Text>
            .{" "}
            {props.hostedInvoiceUrl ? (
              <Text as="span">{t("pendingPayment.continuePayOnline")}</Text>
            ) : (
              <Text as="span">{t("pendingPayment.continuePayReception")}</Text>
            )}
          </Text>
        </VStack>
        {props.hostedInvoiceUrl ? (
          <VStack w="full" alignItems="start" spacing={4}>
            <Button
              as="a"
              href={props.hostedInvoiceUrl}
              onClick={() => setShowRefresh(true)}
              target="_blank"
              borderRadius={4}
              w={{ base: "100%", md: "auto" }}
              colorScheme="blue"
              rightIcon={
                <Icon>
                  <ArrowTopRightOnSquareIcon />
                </Icon>
              }
            >
              {t("pendingPayment.proceedToPayment")}
            </Button>
            <Text fontSize="xs">
              {t("pendingPayment.redirectMessage")}{" "}
              <Link href="https://stripe.com" target="_blank">
                {t("pendingPayment.stripe")}
              </Link>
              .
            </Text>
          </VStack>
        ) : null}
      </VStack>
    </VStack>
  );
};
