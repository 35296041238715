import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInWithCustomToken } from "firebase/auth";
import { TFunction } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { auth } from "../../auth/config";
import { useCreateUserMutation } from "../../graphql/generated/user-hooks";
import * as Sentry from "@sentry/react";
import { Button } from "../../components/chackra-ui-button";

type FormValues = {
  email: string;
  emailConfirmation?: string;
  password: string;
  passwordConfirmation?: string;
  acceptTerms?: boolean;
};

const validationSchema = (t: TFunction) =>
  Yup.object({
    email: Yup.string().required(t("register.emailRequired")),
    emailConfirmation: Yup.string().oneOf(
      [Yup.ref("email")],
      t("register.emailsMustMatch")
    ),
    password: Yup.string()
      .required(t("register.passwordRequired"))
      .min(6, t("register.passwordMinLength")),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password")], t("register.passwordsMustMatch"))
      .min(6, t("register.passwordMinLength")),
    acceptTerms: Yup.boolean().oneOf([true], t("register.mustAcceptTerms")),
  });

export const RegisterEmailForm = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
  });

  const [createUser] = useCreateUserMutation({
    context: {
      endpoint: "fitnext",
    },
    onCompleted: (data) => {
      if (data?.token) {
        signInWithCustomToken(auth, data.token).catch((error) => {
          Sentry.captureException(error);
        });
      }
    },
    onError: (error) => {
      toast({
        title: error.message,
        status: "error",
      });
      Sentry.captureException(error);
    },
  });

  const onSubmit = handleSubmit(
    async ({ email, password }) => {
      try {
        await createUser({
          variables: { input: { email, password } },
        });
      } catch (err) {
        Sentry.captureException(err);
      }
    },
    (errors, event) => {
      Sentry.captureException(new Error("Unable to submit register form"), {
        extra: {
          event,
          errors,
        },
      });
    }
  );

  return (
    <form onSubmit={onSubmit}>
      <SimpleGrid columns={1} spacing={6}>
        {/* <FormControl isInvalid={!!errors.phoneNumber}>
          <FormLabel htmlFor="phoneNumber">Teléfono</FormLabel>
          <PhoneInput
            id="phoneNumber"
            onChange={(value) => setValue("phoneNumber", value)}
            value={watch("phoneNumber")}
          />
          {!!errors.phoneNumber && errors.phoneNumber?.message ? (
            <FormErrorMessage>{errors.phoneNumber.message}</FormErrorMessage>
          ) : null}
        </FormControl> */}
        <FormControl isInvalid={!!errors.email}>
          <FormLabel htmlFor="email">{t("register.email")}</FormLabel>
          <Input
            id="email"
            size="lg"
            type="email"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            borderRadius={4}
            {...register("email")}
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.emailConfirmation}>
          <FormLabel htmlFor="emailConfirmation">
            {t("register.confirmEmail")}
          </FormLabel>
          <Input
            id="emailConfirmation"
            size="lg"
            type="email"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            borderRadius={4}
            {...register("emailConfirmation")}
          />
          <FormErrorMessage>
            {errors.emailConfirmation?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.password}>
          <FormLabel htmlFor="password">{t("register.password")}</FormLabel>
          <Input
            id="password"
            size="lg"
            type="password"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            borderRadius={4}
            {...register("password")}
          />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.passwordConfirmation}>
          <FormLabel htmlFor="passwordConfirmation">
            {t("register.confirmPassword")}
          </FormLabel>
          <Input
            id="passwordConfirmation"
            size="lg"
            type="password"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            borderRadius={4}
            {...register("passwordConfirmation")}
          />
          <FormErrorMessage>
            {errors.passwordConfirmation?.message}
          </FormErrorMessage>
        </FormControl>
        {/* <FormControl>
          <Checkbox alignItems="start">
            <Text textAlign="start" mt={-1}>
              Me gustaría mantenerme informado/a sobre promociones especiales y
              eventos que se organicen en el club.
            </Text>
          </Checkbox>
        </FormControl> */}
        <FormControl isInvalid={!!errors.acceptTerms}>
          <Checkbox size="lg" alignItems="start" {...register("acceptTerms")}>
            <Text fontSize="md" textAlign="start" mt={-1} ml={6}>
              {t("register.acceptTerms")}{" "}
              <Link
                as="a"
                href="https://clubsensei.com/privacidad"
                target="_blank"
                color="blue.500"
              >
                {t("register.privacyPolicy")}
              </Link>
              .
            </Text>
          </Checkbox>
          <FormErrorMessage>{errors.acceptTerms?.message}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <Button
        type="submit"
        colorScheme="blue"
        size="lg"
        w="full"
        isLoading={isSubmitting}
        mt={6}
        borderRadius={4}
      >
        {t("register.createAccountButton")}
      </Button>
    </form>
  );
};
